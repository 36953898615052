// Importações necessárias do React, componentes e estilos
import React, { useState, useEffect } from "react";
import BackgroundCarousel from "./BackgroundCarousel";
import "./HomePage.css";
import { SignLanguage } from "@mui/icons-material";

// Array com os caminhos das imagens para o carrossel de fundo
const images = [
  "/backgrounds/bcknd1.png",
  "/backgrounds/bcknd2.png",
  "/backgrounds/bcknd3.png",
  "/backgrounds/bcknd4.png",
  "/backgrounds/bcknd5.png",
  "/backgrounds/bcknd6.png",
];

const IntroAndFeatures = ({ onSignLanguageClick }) => {
  // Estado para gerenciar a largura da janela
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Função para atualizar a largura da janela
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Adicionando um ouvinte de evento para redimensionamento da janela
    window.addEventListener("resize", handleResize);

    // Limpeza: remover o ouvinte de evento quando o componente for desmontado
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="content">
      <div className="text-content">
        <SignLanguage
          className="sign-language-video"
          onClick={onSignLanguageClick}
        />
        <p>
          A groundbreaking online platform designed for translating the Bible
          into Sign Language. This tool is the culmination of years of dedicated
          work and feedback from experienced translation teams, crafted to meet
          the specific needs and challenges in this field.
        </p>
        <h2>Features:</h2>
        <ul>
          <li>
            <strong>Video Drafts Creation:</strong> Create initial drafts of
            your Sign Language translations.
          </li>
          <li>
            <strong>Feedback Markers:</strong> Mark translations on a timeline
            beneath the video with markers, and provide feedback using annotated
            notes for easy reference and discussion.
          </li>
          <li>
            <strong>Biblical Passage Tagging:</strong> Mark videos according to
            specific Bible passages for easy navigation.
          </li>
          <li>
            <strong>Translation Comparisons:</strong> Compare your drafts with
            existing translations to ensure accuracy and consistency.
          </li>
          <li>
            <strong>Video Patching:</strong> View &apos;patched&apos; versions
            of your translations to see how the final product/reviewed video
            should look.
          </li>
          <li>
            <strong>Gloss Marking:</strong> Mark videos with a gloss to aid the
            work of consultants.
          </li>
          <li>
            <strong>Professional Exegetical Analysis:</strong> Enable
            professional exegetical consultants to provide comments,
            suggestions, theological and bibliological corrections, and approve
            or disapprove translations.
          </li>
          <li>
            <strong>Project Planning:</strong> Track the progress of
            translations, offering transparency to stakeholders and investors.
          </li>
        </ul>
        <p>
          The SLTT is not just a tool; it&apos;s a bridge connecting the sacred
          texts with the Sign Language community, ensuring inclusivity and
          accessibility in spiritual matters. Join us in this noble endeavor to
          make the Bible accessible to everyone.
        </p>
      </div>
      {windowWidth >= 768 && <BackgroundCarousel images={images} />}
    </div>
  );
};

export default IntroAndFeatures;
