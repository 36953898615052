import React from "react";
import "./HomePage.css";

const Testimonies = () => (
  <div className="translation-teams-container">
    <h3>Testimonies</h3>
    <div className="testimonies-container">
      <ul className="translation-teams-text">
        <li>
          <p>Pastor Oleg Stralvinski</p>
          <sup>Ukrainian Sign Language Bible Translation Team</sup>
        </li>
        <li>
          <p>Ignacio Alvarez</p>
          <sup>Costa Rican Sign Language Bible Translation Team</sup>
        </li>
        <li>
          <p>Ivan Unda</p>
          <sup>Ecuadorian Sign Language Bible Translation Team</sup>
        </li>
        <li>
          <p>Ngo Quoc Hoa</p>
          <sup>Vietnamese Sign Language Bible Translation Team</sup>
        </li>
      </ul>
    </div>
    <div className="translation-teams-video">
      <video
        className="videoPlayer"
        controls
        muted
        preload="none"
        src="/videos/UserExperience.mp4"
      ></video>
    </div>
  </div>
);

export default Testimonies;
