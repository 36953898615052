import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css";

const RequestAccessButton = () => (
  <div className="translation-app-request">
    <Link to="/request-access">
      <button>Request Access</button>
    </Link>
  </div>
);

export default RequestAccessButton;
