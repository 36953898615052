import React, { useState } from "react";
import "./HomePage.css";
import IntroAndFeatures from "./IntroAndFeatures";
import IntroAndFeaturesSignedVersion from "./IntroAndFeaturesSignedVersion";
import VideoDemo from "./VideoDemo";
import Testimonies from "./Testimonies";
import RequestAccessButton from "./RequestAccessButton";

const HomePage = () => {
  const [isSignedVersionVisible, setIsSignedVersionVisible] = useState(true);

  const handleSignLanguageClick = () => {
    setIsSignedVersionVisible(true);

    setTimeout(() => {
      const element = document.getElementById("intro-signed");
      if (element) {
        element.classList.remove("starting");
      }
    }, 0);
  };

  return (
    <div>
      {isSignedVersionVisible && (
        <IntroAndFeaturesSignedVersion
          onClose={() => setIsSignedVersionVisible(false)}
        />
      )}
      <IntroAndFeatures onSignLanguageClick={handleSignLanguageClick} />

      <VideoDemo videoSrc="/videos/SLTTPlanAndProgress.mp4" />
      <Testimonies />
      <RequestAccessButton />
    </div>
  );
};

export default HomePage;
