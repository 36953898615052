import React, { useRef, useEffect } from "react";
import "./HomePage.css";

const VideoDemo = ({ videoSrc }) => {
  const videoRef = useRef();

  useEffect(() => {
    let videoElement = videoRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && videoElement) {
            videoElement.play().catch((error) => {
              console.error("Error trying to play the video:", error);
            });
          } else if (videoElement) {
            videoElement.pause();
          }
        });
      },
      { threshold: 1.0 },
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  return (
    <div className="video-container">
      <h3 className="video-title">Demonstration of the tool</h3>
      <video
        ref={videoRef}
        className="videoPlayer"
        controls
        muted
        preload="none"
        src={videoSrc}
      ></video>
    </div>
  );
};

export default VideoDemo;
