import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import RequestAccessForm from "./RequestAccessForm";
import logo from "./sltt-squared.png";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MailIcon from "@mui/icons-material/Mail";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function App() {
  const handleExitClick = () => {
    window.open("https://sltt-bible.net", "_blank");
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Sign Language Translation Tool</h1>
          <div className="header-icons">
            <a
              href="mailto:caioc@doorinternational.org"
              style={{ color: "inherit", margin: 0, padding: 0 }}
            >
              <MailIcon />
            </a>
            <ExitToAppIcon
              onClick={handleExitClick}
              style={{ cursor: "pointer" }}
            />
          </div>
        </header>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/request-access" element={<RequestAccessForm />} />
        </Routes>
        <footer className="App-footer">
          <div className="footer-content">
            <div className="footer-section">
              <h4>&copy; All rights reserved</h4>
            </div>
            <div className="footer-images">
              <a
                href="https://doorinternational.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/sponsors/door.png"
                  alt="DOOR International"
                  style={{ height: "100px" }}
                />
              </a>
              <a
                href="https://unitedbiblesocieties.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/sponsors/ubs.png"
                  alt="United Bible Societies"
                  style={{ height: "100px" }}
                />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
