import React, { useState, useEffect } from "react";
import "./HomePage.css";
import "./IntroAndFeaturesSignedVersion.css";
import Close from "@mui/icons-material/Close";

const IntroAndFeaturesSignedVersion = ({ onClose }) => {
  const [opacity] = useState(1);

  const handleClose = () => {
    document.getElementById("intro-signed").classList.add("closed");
    setTimeout(() => {
      onClose(); // Chama a função onClose após o término da transição
    }, 500);
  };

  useEffect(() => {
    const element = document.getElementById("intro-signed");
    if (element) {
      element.classList.add("starting");
      setTimeout(() => element.classList.remove("starting"), 10);
    }
  }, []);

  if (opacity === null) return null;

  return (
    <div id="intro-signed" style={{ opacity }}>
      <Close className="close-button" onClick={handleClose} />
      <video
        className="videoPlayerIntro"
        controls
        muted
        autoPlay
        preload="none"
        src="/videos/IntroSigned.mp4"
      ></video>
    </div>
  );
};

export default IntroAndFeaturesSignedVersion;
