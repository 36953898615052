import React, { useState } from "react";
import "./RequestAccessForm.css";

function RequestAccessForm() {
  const [showForm] = useState(true);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [formData, setFormData] = useState({
    projectName: "",
    projectDescription: "",
    teamName: "",
    signLanguage: "",
    adminNameEmail: "",
    projectGoal: "",
    projectShortCode: "",
    preferredLanguage: "",
    countryRegion: "",
    completionDate: "",
    titlePublished: "",
    translationScope: "",
    translationType: "",
    organizationalPlan: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://a0qe8u4dq0.execute-api.us-east-1.amazonaws.com/prod/access-request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setFormData({
        projectName: "",
        projectDescription: "",
        teamName: "",
        signLanguage: "",
        adminNameEmail: "",
        projectGoal: "",
        projectShortCode: "",
        preferredLanguage: "",
        countryRegion: "",
        completionDate: "",
        titlePublished: "",
        translationScope: "",
        translationType: "",
        organizationalPlan: "",
      });

      setShowSuccessPopup(true);
      setTimeout(() => setShowSuccessPopup(false), 30000);
    } catch (error) {
      setError("Failure Sending form: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {showForm && (
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <h2>Project Access Request Form</h2>
            <div className="form-introduction">
              <p>
                To ensure effective collaboration and proper support for your
                sign language translation project, it is essential that you fill
                out the form below in detail. The information provided will be
                crucial for us to understand the scope and specific details of
                your project, allowing us to assess whether we can grant access
                to the application in a way that makes sense and meets your
                needs.
              </p>
              <p>
                Your request for access is a vital step that enables us to
                contact the project administrators and establish a productive
                partnership. This procedure is important not only for the
                initial setup of systems and resource allocation but also for
                ongoing support and guidance throughout the project&apos;s
                development.
              </p>
              <p>
                We ask that you provide clear and accurate details in the
                required fields and, as applicable, in the optional fields.
                These additional details are significant in enriching our
                understanding of the project and ensuring that the support
                provided is in full harmony with the goals of your team.
              </p>
            </div>

            <label htmlFor="projectName">Preferred Project Name:</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="projectName"
              name="projectName"
              value={formData.projectName}
              required
            />

            <label htmlFor="projectDescription">Project Description:</label>
            <textarea
              onChange={handleInputChange}
              id="projectDescription"
              name="projectDescription"
              value={formData.projectDescription}
              required
            ></textarea>

            <label htmlFor="teamName">Team Name:</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="teamName"
              name="teamName"
              value={formData.teamName}
              required
            />

            <label htmlFor="signLanguage">
              Sign Language being translated:
            </label>
            <input
              onChange={handleInputChange}
              type="text"
              id="signLanguage"
              name="signLanguage"
              value={formData.signLanguage}
              required
            />

            <label htmlFor="adminNameEmail">Admin(s) Name and Email(s):</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="adminNameEmail"
              name="adminNameEmail"
              value={formData.adminNameEmail}
              required
            />

            <label htmlFor="projectGoal">Project Goal:</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="projectGoal"
              name="projectGoal"
              value={formData.projectGoal}
              required
            />

            <label htmlFor="projectShortCode">Project Shortcode:</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="projectShortCode"
              name="projectShortCode"
              value={formData.projectShortCode}
              required
            />

            <label htmlFor="preferredLanguage">
              Preferred Written Language:
            </label>
            <input
              onChange={handleInputChange}
              type="text"
              id="preferredLanguage"
              placeholder="optional"
              value={formData.preferredLanguage}
              name="preferredLanguage"
            />

            <label htmlFor="countryRegion">Country and Region:</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="countryRegion"
              value={formData.countryRegion}
              placeholder="optional"
              name="countryRegion"
            />

            <label htmlFor="completionDate">Target Completion Date:</label>
            <input
              onChange={handleInputChange}
              type="date"
              value={formData.completionDate}
              id="completionDate"
              placeholder="optional"
              name="completionDate"
            />

            <label htmlFor="titlePublished">Title when Published:</label>
            <input
              onChange={handleInputChange}
              type="text"
              value={formData.titlePublished}
              id="titlePublished"
              placeholder="optional"
              name="titlePublished"
            />

            <label htmlFor="translationScope">Translation Scope:</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="translationScope"
              placeholder="optional"
              name="translationScope"
              value={formData.translationScope}
            />

            <label htmlFor="translationType">Translation Type:</label>
            <input
              onChange={handleInputChange}
              type="text"
              id="translationType"
              value={formData.translationType}
              placeholder="optional"
              name="translationType"
            />

            <label htmlFor="organizationalPlan">
              Organizational Plan/Stages/Tasks:
            </label>
            <textarea
              onChange={handleInputChange}
              id="organizationalPlan"
              placeholder="optional"
              value={formData.organizationalPlan}
              name="organizationalPlan"
            ></textarea>

            <input
              type="submit"
              value={isLoading ? "Sending... Please wait" : "Send request"}
              disabled={isLoading}
              className={isLoading ? "button-sending" : ""}
            />
          </form>
          {error && <div className="error">{error}</div>}
          {showSuccessPopup && (
            <div className="success">
              Form submitted successfully! We will contact you via the email
              address you provided. Thank you!
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RequestAccessForm;
